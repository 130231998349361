import React from "react";

export const FooterDonorsSvg = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4286 11.2642C10.4533 8.1657 11.4751 6.11735 12.6945 4.82508C14.7407 2.67789 17.6163 2.94722 19.6131 4.89921C20.9288 6.48551 21.8791 8.45973 22 10.6292C22 12.1413 21.7803 14.2391 20.5388 15.9712C19.2232 17.7996 17.0066 19.1907 14.2767 17.849C13.3511 19.1413 12.9364 19.5564 12.2798 20.1667C14.2051 21.1427 16.3723 20.3866 16.9573 20.2161C15.9107 21.2416 12.5711 21.9482 11.3048 21.9976C8.86862 22.0471 6.72366 21.3157 4.97117 20.1692C8.60204 21.3898 10.0139 20.2656 10.5742 20.0951C14.642 17.7304 15.1776 13.1198 15.1776 10.8515C15.1036 8.8526 14.1533 5.75413 13.472 5.50951C12.6427 5.50951 11.3048 8.97368 11.4258 11.2172C11.3542 14.3132 10.1595 16.117 8.9402 17.3623C6.94334 19.3859 4.26276 18.704 2.55716 17.1177C1.12061 15.7019 0.0493661 13.5819 0 11.1431C0 8.89955 0.584988 6.60658 2.24122 5.02275C4.19118 3.26596 5.94368 3.07076 7.64928 4.09617C8.25895 3.02382 8.9402 2.50987 9.64614 1.7785C8.86862 1.14348 6.38304 1.1929 5.09211 1.72908C4.67744 1.67966 8.138 -0.22291 11.0605 0.0217061C14.326 0.0933614 15.5429 0.874157 16.8832 1.72908C14.326 1.04465 13.2523 0.997701 11.7911 1.60554C8.40458 3.01887 6.6743 7.09334 6.79771 10.9701C6.79771 13.4089 7.84427 16.3838 8.45394 16.5074C8.79457 16.6779 10.5742 13.7771 10.4286 11.2642Z"
      fill="#09354F"
    />
  </svg>
);
