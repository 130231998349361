




export const donorsArray = [
`<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49554)">
<path d="M22.6176 0.986973H0V15.6219H22.6176V0.986973Z" fill="#003399"/>
<path d="M10.9744 2.613L10.7917 3.17482H10.2012L10.679 3.52199L10.4966 4.08381L10.9744 3.73664L11.4522 4.08381L11.2698 3.52199L11.7476 3.17482H11.1571L10.9744 2.613Z" fill="#FFCC00"/>
<path d="M11.1571 12.9315L10.9744 12.3696L10.7917 12.9315H10.2012L10.679 13.2786L10.4966 13.8404L10.9744 13.4933L11.4522 13.8404L11.2698 13.2786L11.7476 12.9315H11.1571Z" fill="#FFCC00"/>
<path d="M6.09743 8.61505L6.57524 8.96223L6.39284 8.40041L6.87065 8.05323H6.2801L6.09743 7.49142L5.91477 8.05323H5.32422L5.80202 8.40041L5.61963 8.96223L6.09743 8.61505Z" fill="#FFCC00"/>
<path d="M8.53689 3.26668L8.35422 3.82823H7.76367L8.24148 4.17568L8.05908 4.7375L8.53689 4.39005L9.01469 4.7375L8.83229 4.17568L9.3101 3.82823H8.71955L8.53689 3.26668Z" fill="#FFCC00"/>
<path d="M6.74809 5.05201L6.56543 5.61383H5.97461L6.45268 5.961L6.27002 6.52282L6.74809 6.17565L7.22589 6.52282L7.04323 5.961L7.5213 5.61383H6.93049L6.74809 5.05201Z" fill="#FFCC00"/>
<path d="M6.93049 10.4922L6.74809 9.93042L6.56543 10.4922H5.97461L6.45268 10.8394L6.27002 11.4012L6.74809 11.0541L7.22589 11.4012L7.04323 10.8394L7.5213 10.4922H6.93049Z" fill="#FFCC00"/>
<path d="M8.71955 12.2778L8.53689 11.716L8.35422 12.2778H7.76367L8.24148 12.6252L8.05908 13.187L8.53689 12.8396L9.01469 13.187L8.83229 12.6252L9.3101 12.2778H8.71955Z" fill="#FFCC00"/>
<path d="M16.6285 8.05323H16.0379L15.8552 7.49142L15.6726 8.05323H15.082L15.5598 8.40041L15.3774 8.96223L15.8552 8.61505L16.333 8.96223L16.1507 8.40041L16.6285 8.05323Z" fill="#FFCC00"/>
<path d="M13.4158 3.26668L13.2331 3.82823H12.6426L13.1204 4.17568L12.938 4.7375L13.4158 4.39005L13.8936 4.7375L13.7112 4.17568L14.189 3.82823H13.5985L13.4158 3.26668Z" fill="#FFCC00"/>
<path d="M14.9058 5.961L14.7231 6.52282L15.2009 6.17565L15.679 6.52282L15.4964 5.961L15.9744 5.61383H15.3836L15.2009 5.05201L15.0186 5.61383H14.4277L14.9058 5.961Z" fill="#FFCC00"/>
<path d="M15.3836 10.4922L15.2009 9.93042L15.0186 10.4922H14.4277L14.9058 10.8394L14.7231 11.4012L15.2009 11.0541L15.679 11.4012L15.4964 10.8394L15.9744 10.4922H15.3836Z" fill="#FFCC00"/>
<path d="M13.5985 12.2778L13.4158 11.716L13.2331 12.2778H12.6426L13.1204 12.6252L12.938 13.187L13.4158 12.8396L13.8936 13.187L13.7112 12.6252L14.189 12.2778H13.5985Z" fill="#FFCC00"/>
</g>
<defs>
<clipPath id="clip0_892:49554">
<rect y="0.986816" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `
    <svg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_892:49569)">
<g clip-path="url(#clip0_892:49569)">
<path d="M25.6157 2.98622H2.99805V17.6212H25.6157V2.98622Z" fill="white"/>
<path d="M25.6157 8.30825H2.99805V12.2996H25.6157V8.30825Z" fill="#003580"/>
<path d="M13.0509 2.98638H9.28125V17.6213H13.0509V2.98638Z" fill="#003580"/>
</g>
</g>
<defs>
<filter id="filter0_d_892:49569" x="0.554851" y="0.541179" width="27.5075" height="19.5252" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="1.22257"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_892:49569"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_892:49569" result="shape"/>
</filter>
<clipPath id="clip0_892:49569">
<rect x="3" y="2.98633" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
    `,
    `<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49573)">
<path d="M22.6196 0.986414H0.00195312V15.6214H22.6196V0.986414Z" fill="#C60C30"/>
<path d="M9.78304 0.98658H7.33789V15.6215H9.78304V0.98658Z" fill="white"/>
<path d="M22.6196 7.25863H0.00195312V9.34933H22.6196V7.25863Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_892:49573">
<rect y="0.986328" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49577)">
<path d="M22.6176 0.986591H0V15.6215H22.6176V0.986591Z" fill="#ED2939"/>
<path d="M15.0784 0.986591H0V15.6215H15.0784V0.986591Z" fill="white"/>
<path d="M7.53922 0.986591H0V15.6215H7.53922V0.986591Z" fill="#002395"/>
</g>
<defs>
<clipPath id="clip0_892:49577">
<rect y="0.986328" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:4319)">
<path d="M22.6157 0.441406H-0.00195312V15.0764H22.6157V0.441406Z" fill="#009246"/>
<path d="M22.6175 0.441406H7.53906V15.0764H22.6175V0.441406Z" fill="white"/>
<path d="M22.6173 0.441406H15.0781V15.0764H22.6173V0.441406Z" fill="#CE2B37"/>
</g>
<defs>
<clipPath id="clip0_892:4319">
<rect y="0.441406" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>

`,
    `
    <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49585)">
<path d="M22.6196 0.98658H0.00195312V15.6215H22.6196V0.98658Z" fill="#21468B"/>
<path d="M22.6196 0.98658H0.00195312V10.7432H22.6196V0.98658Z" fill="white"/>
<path d="M22.6196 0.98658H0.00195312V5.86489H22.6196V0.98658Z" fill="#AE1C28"/>
</g>
<defs>
<clipPath id="clip0_892:49585">
<rect y="0.986328" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>

    `,
    `<svg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_892:49589)">
<g clip-path="url(#clip0_892:49589)">
<path d="M25.6176 2.98638H3V17.6213H25.6176V2.98638Z" fill="#DC143C"/>
<path d="M25.6176 2.98638H3V10.3039H25.6176V2.98638Z" fill="white"/>
</g>
</g>
<defs>
<filter id="filter0_d_892:49589" x="0.554851" y="0.541179" width="27.5075" height="19.5252" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="1.22257"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_892:49589"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_892:49589" result="shape"/>
</filter>
<clipPath id="clip0_892:49589">
<rect x="3" y="2.98633" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49592)">
<path d="M22.6157 0.98658H-0.00195312V15.6215H22.6157V0.98658Z" fill="#006AA7"/>
<path d="M9.89361 0.98658H7.06641V15.6215H9.89361V0.98658Z" fill="#FECC00"/>
<path d="M22.6157 6.84036H-0.00195312V9.76735H22.6157V6.84036Z" fill="#FECC00"/>
</g>
<defs>
<clipPath id="clip0_892:49592">
<rect y="0.986328" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49596)">
<path d="M0.00390625 0.986382V15.6213H22.6215V0.986382H0.00390625Z" fill="#012169"/>
<path d="M0.00390625 0.986382L22.6215 15.6213L0.00390625 0.986382ZM22.6215 0.986382L0.00390625 15.6213L22.6215 0.986382Z" fill="black"/>
<path d="M22.6215 0.986382L0.00390625 15.6213M0.00390625 0.986382L22.6215 15.6213L0.00390625 0.986382Z" stroke="white" stroke-width="3.27757"/>
<path d="M0.00390625 0.986382L22.6215 15.6213L0.00390625 0.986382ZM22.6215 0.986382L0.00390625 15.6213L22.6215 0.986382Z" fill="black"/>
<path d="M22.6215 0.986382L0.00390625 15.6213M0.00390625 0.986382L22.6215 15.6213L0.00390625 0.986382Z" stroke="#C8102E" stroke-width="2.18505"/>
<path d="M11.3127 0.986382V15.6213V0.986382ZM0.00390625 8.30385H22.6215H0.00390625Z" fill="black"/>
<path d="M0.00390625 8.30385H22.6215M11.3127 0.986382V15.6213V0.986382Z" stroke="white" stroke-width="5.46261"/>
<path d="M11.3127 0.986382V15.6213V0.986382ZM0.00390625 8.30385H22.6215H0.00390625Z" fill="black"/>
<path d="M0.00390625 8.30385H22.6215M11.3127 0.986382V15.6213V0.986382Z" stroke="#C8102E" stroke-width="3.27757"/>
</g>
<defs>
<clipPath id="clip0_892:49596">
<rect y="0.986328" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `<svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49603)">
<path d="M22.6176 0.986414H0V15.6214H22.6176V0.986414Z" fill="black"/>
<path d="M22.6176 5.86499H0V15.6216H22.6176V5.86499Z" fill="#DD0000"/>
<path d="M22.6176 10.743H0V15.6213H22.6176V10.743Z" fill="#FFCE00"/>
</g>
<defs>
<clipPath id="clip0_892:49603">
<rect y="0.986328" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `<svg width="29" height="21" viewBox="0 0 29 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_892:49607)">
<g clip-path="url(#clip0_892:49607)">
<path d="M25.6176 3.24911H3V17.8841H25.6176V3.24911Z" fill="white"/>
<path d="M13.9745 14.9568C16.3993 14.9568 18.365 12.9911 18.365 10.5663C18.365 8.14154 16.3993 6.17586 13.9745 6.17586C11.5497 6.17586 9.58398 8.14154 9.58398 10.5663C9.58398 12.9911 11.5497 14.9568 13.9745 14.9568Z" fill="#BC002D"/>
</g>
</g>
<defs>
<filter id="filter0_d_892:49607" x="0.554851" y="0.803874" width="27.5075" height="19.5252" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="1.22257"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_892:49607"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_892:49607" result="shape"/>
</filter>
<clipPath id="clip0_892:49607">
<rect x="3" y="3.24902" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,`<svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49610)">
<path d="M22.6196 0.248768H0.00195312V14.8837H22.6196V0.248768Z" fill="#EF2B2D"/>
<path d="M10.2803 0.248768H6.16797V14.8837H10.2803V0.248768Z" fill="white"/>
<path d="M22.6196 5.73703H0.00195312V9.39577H22.6196V5.73703Z" fill="white"/>
<path d="M9.25146 0.248768H7.19531V14.8837H9.25146V0.248768Z" fill="#002868"/>
<path d="M22.6196 6.65151H0.00195312V8.48088H22.6196V6.65151Z" fill="#002868"/>
</g>
<defs>
<clipPath id="clip0_892:49610">
<rect y="0.249023" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `<svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49616)">
<path d="M0.00195312 0.248915H22.6196V14.8839H0.00195312V0.248915Z" fill="#FF0000"/>
<path d="M9.93968 2.9928H12.6837V6.1942H15.8851V8.93825H12.6837V12.1396H9.93968V8.93825H6.73828V6.1942H9.93968V2.9928Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_892:49616">
<rect y="0.249023" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`,
    `<svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_892:49619)">
<path d="M22.6176 0.248768H0V14.8837H22.6176V0.248768Z" fill="#B22234"/>
<path d="M0 1.93731H22.6176H0ZM22.6176 4.18884H0H22.6176ZM0 6.44037H22.6176H0ZM22.6176 8.6919H0H22.6176ZM0 10.9434H22.6176H0ZM22.6176 13.195H0H22.6176Z" fill="black"/>
<path d="M22.6176 13.195H0M0 1.93731H22.6176H0ZM22.6176 4.18884H0H22.6176ZM0 6.44037H22.6176H0ZM22.6176 8.6919H0H22.6176ZM0 10.9434H22.6176H0Z" stroke="white" stroke-width="0.921081" stroke-miterlimit="10"/>
<path d="M11.3088 0.248768H0V8.23147H11.3088V0.248768Z" fill="#3C3B6E"/>
<path d="M0.933803 0.590939L0.831396 0.906141H0.5L0.767991 1.10092L0.665584 1.41612L0.933803 1.22134L1.20179 1.41612L1.09939 1.10092L1.36761 0.906141H1.03621L0.933803 0.590939Z" fill="white"/>
<path d="M0.933803 2.18746L0.831396 2.50266H0.5L0.767991 2.69744L0.665584 3.01264L0.933803 2.81787L1.20179 3.01264L1.09939 2.69744L1.36761 2.50266H1.03621L0.933803 2.18746Z" fill="white"/>
<path d="M0.933803 3.78398L0.831396 4.09918H0.5L0.767991 4.29396L0.665584 4.60916L0.933803 4.41439L1.20179 4.60916L1.09939 4.29396L1.36761 4.09918H1.03621L0.933803 3.78398Z" fill="white"/>
<path d="M0.933803 5.3805L0.831396 5.69571H0.5L0.767991 5.89048L0.665584 6.20569L0.933803 6.01091L1.20179 6.20569L1.09939 5.89048L1.36761 5.69571H1.03621L0.933803 5.3805Z" fill="white"/>
<path d="M1.03621 7.29243L0.933803 6.97722L0.831396 7.29243H0.5L0.767991 7.4872L0.665584 7.80241L0.933803 7.60763L1.20179 7.80241L1.09939 7.4872L1.36761 7.29243H1.03621Z" fill="white"/>
<path d="M1.86935 1.3892L1.76694 1.7044H1.43555L1.70377 1.89918L1.60136 2.21438L1.86935 2.01961L2.13757 2.21438L2.03516 1.89918L2.30315 1.7044H1.97176L1.86935 1.3892Z" fill="white"/>
<path d="M1.86935 2.98592L1.76694 3.30112H1.43555L1.70377 3.4959L1.60136 3.8111L1.86935 3.61632L2.13757 3.8111L2.03516 3.4959L2.30315 3.30112H1.97176L1.86935 2.98592Z" fill="white"/>
<path d="M1.86935 4.58224L1.76694 4.89745H1.43555L1.70377 5.09222L1.60136 5.40743L1.86935 5.21265L2.13757 5.40743L2.03516 5.09222L2.30315 4.89745H1.97176L1.86935 4.58224Z" fill="white"/>
<path d="M1.97176 6.49397L1.86935 6.17876L1.76694 6.49397H1.43555L1.70377 6.68874L1.60136 7.00395L1.86935 6.80917L2.13757 7.00395L2.03516 6.68874L2.30315 6.49397H1.97176Z" fill="white"/>
<path d="M2.81271 0.590939L2.7103 0.906141H2.37891L2.6469 1.10092L2.54449 1.41612L2.81271 1.22134L3.08093 1.41612L2.97852 1.10092L3.24651 0.906141H2.91512L2.81271 0.590939Z" fill="white"/>
<path d="M2.81271 2.18746L2.7103 2.50266H2.37891L2.6469 2.69744L2.54449 3.01264L2.81271 2.81787L3.08093 3.01264L2.97852 2.69744L3.24651 2.50266H2.91512L2.81271 2.18746Z" fill="white"/>
<path d="M2.81271 3.78398L2.7103 4.09918H2.37891L2.6469 4.29396L2.54449 4.60916L2.81271 4.41439L3.08093 4.60916L2.97852 4.29396L3.24651 4.09918H2.91512L2.81271 3.78398Z" fill="white"/>
<path d="M2.81271 5.3805L2.7103 5.69571H2.37891L2.6469 5.89048L2.54449 6.20569L2.81271 6.01091L3.08093 6.20569L2.97852 5.89048L3.24651 5.69571H2.91512L2.81271 5.3805Z" fill="white"/>
<path d="M2.91512 7.29243L2.81271 6.97722L2.7103 7.29243H2.37891L2.6469 7.4872L2.54449 7.80241L2.81271 7.60763L3.08093 7.80241L2.97852 7.4872L3.24651 7.29243H2.91512Z" fill="white"/>
<path d="M3.75216 1.3892L3.64976 1.7044H3.31836L3.58635 1.89918L3.48394 2.21438L3.75216 2.01961L4.02015 2.21438L3.91775 1.89918L4.18596 1.7044H3.85457L3.75216 1.3892Z" fill="white"/>
<path d="M3.75216 2.98592L3.64976 3.30112H3.31836L3.58635 3.4959L3.48394 3.8111L3.75216 3.61632L4.02015 3.8111L3.91775 3.4959L4.18596 3.30112H3.85457L3.75216 2.98592Z" fill="white"/>
<path d="M3.75216 4.58224L3.64976 4.89745H3.31836L3.58635 5.09222L3.48394 5.40743L3.75216 5.21265L4.02015 5.40743L3.91775 5.09222L4.18596 4.89745H3.85457L3.75216 4.58224Z" fill="white"/>
<path d="M3.85457 6.49397L3.75216 6.17876L3.64976 6.49397H3.31836L3.58635 6.68874L3.48394 7.00395L3.75216 6.80917L4.02015 7.00395L3.91775 6.68874L4.18596 6.49397H3.85457Z" fill="white"/>
<path d="M4.68771 0.590939L4.5853 0.906141H4.25391L4.52213 1.10092L4.41972 1.41612L4.68771 1.22134L4.95593 1.41612L4.85352 1.10092L5.12151 0.906141H4.79012L4.68771 0.590939Z" fill="white"/>
<path d="M4.68771 2.18746L4.5853 2.50266H4.25391L4.52213 2.69744L4.41972 3.01264L4.68771 2.81787L4.95593 3.01264L4.85352 2.69744L5.12151 2.50266H4.79012L4.68771 2.18746Z" fill="white"/>
<path d="M4.68771 3.78398L4.5853 4.09918H4.25391L4.52213 4.29396L4.41972 4.60916L4.68771 4.41439L4.95593 4.60916L4.85352 4.29396L5.12151 4.09918H4.79012L4.68771 3.78398Z" fill="white"/>
<path d="M4.68771 5.3805L4.5853 5.69571H4.25391L4.52213 5.89048L4.41972 6.20569L4.68771 6.01091L4.95593 6.20569L4.85352 5.89048L5.12151 5.69571H4.79012L4.68771 5.3805Z" fill="white"/>
<path d="M4.79012 7.29243L4.68771 6.97722L4.5853 7.29243H4.25391L4.52213 7.4872L4.41972 7.80241L4.68771 7.60763L4.95593 7.80241L4.85352 7.4872L5.12151 7.29243H4.79012Z" fill="white"/>
<path d="M5.62716 1.3892L5.52476 1.7044H5.19336L5.46135 1.89918L5.35894 2.21438L5.62716 2.01961L5.89538 2.21438L5.79297 1.89918L6.06096 1.7044H5.72957L5.62716 1.3892Z" fill="white"/>
<path d="M5.62716 2.98592L5.52476 3.30112H5.19336L5.46135 3.4959L5.35894 3.8111L5.62716 3.61632L5.89538 3.8111L5.79297 3.4959L6.06096 3.30112H5.72957L5.62716 2.98592Z" fill="white"/>
<path d="M5.62716 4.58224L5.52476 4.89745H5.19336L5.46135 5.09222L5.35894 5.40743L5.62716 5.21265L5.89538 5.40743L5.79297 5.09222L6.06096 4.89745H5.72957L5.62716 4.58224Z" fill="white"/>
<path d="M5.72957 6.49397L5.62716 6.17876L5.52476 6.49397H5.19336L5.46135 6.68874L5.35894 7.00395L5.62716 6.80917L5.89538 7.00395L5.79297 6.68874L6.06096 6.49397H5.72957Z" fill="white"/>
<path d="M6.56857 0.590939L6.46616 0.906141H6.13477L6.40276 1.10092L6.30035 1.41612L6.56857 1.22134L6.83656 1.41612L6.73415 1.10092L7.00237 0.906141H6.67098L6.56857 0.590939Z" fill="white"/>
<path d="M6.56857 2.18746L6.46616 2.50266H6.13477L6.40276 2.69744L6.30035 3.01264L6.56857 2.81787L6.83656 3.01264L6.73415 2.69744L7.00237 2.50266H6.67098L6.56857 2.18746Z" fill="white"/>
<path d="M6.56857 3.78398L6.46616 4.09918H6.13477L6.40276 4.29396L6.30035 4.60916L6.56857 4.41439L6.83656 4.60916L6.73415 4.29396L7.00237 4.09918H6.67098L6.56857 3.78398Z" fill="white"/>
<path d="M6.56857 5.3805L6.46616 5.69571H6.13477L6.40276 5.89048L6.30035 6.20569L6.56857 6.01091L6.83656 6.20569L6.73415 5.89048L7.00237 5.69571H6.67098L6.56857 5.3805Z" fill="white"/>
<path d="M6.67098 7.29243L6.56857 6.97722L6.46616 7.29243H6.13477L6.40276 7.4872L6.30035 7.80241L6.56857 7.60763L6.83656 7.80241L6.73415 7.4872L7.00237 7.29243H6.67098Z" fill="white"/>
<path d="M7.50802 1.3892L7.40561 1.7044H7.07422L7.34244 1.89918L7.24003 2.21438L7.50802 2.01961L7.77624 2.21438L7.67383 1.89918L7.94182 1.7044H7.61043L7.50802 1.3892Z" fill="white"/>
<path d="M7.50802 2.98592L7.40561 3.30112H7.07422L7.34244 3.4959L7.24003 3.8111L7.50802 3.61632L7.77624 3.8111L7.67383 3.4959L7.94182 3.30112H7.61043L7.50802 2.98592Z" fill="white"/>
<path d="M7.50802 4.58224L7.40561 4.89745H7.07422L7.34244 5.09222L7.24003 5.40743L7.50802 5.21265L7.77624 5.40743L7.67383 5.09222L7.94182 4.89745H7.61043L7.50802 4.58224Z" fill="white"/>
<path d="M7.61043 6.49397L7.50802 6.17876L7.40561 6.49397H7.07422L7.34244 6.68874L7.24003 7.00395L7.50802 6.80917L7.77624 7.00395L7.67383 6.68874L7.94182 6.49397H7.61043Z" fill="white"/>
<path d="M8.44747 0.590939L8.34507 0.906141H8.01367L8.28166 1.10092L8.17926 1.41612L8.44747 1.22134L8.71569 1.41612L8.61329 1.10092L8.88128 0.906141H8.54988L8.44747 0.590939Z" fill="white"/>
<path d="M8.44747 2.18746L8.34507 2.50266H8.01367L8.28166 2.69744L8.17926 3.01264L8.44747 2.81787L8.71569 3.01264L8.61329 2.69744L8.88128 2.50266H8.54988L8.44747 2.18746Z" fill="white"/>
<path d="M8.44747 3.78398L8.34507 4.09918H8.01367L8.28166 4.29396L8.17926 4.60916L8.44747 4.41439L8.71569 4.60916L8.61329 4.29396L8.88128 4.09918H8.54988L8.44747 3.78398Z" fill="white"/>
<path d="M8.44747 5.3805L8.34507 5.69571H8.01367L8.28166 5.89048L8.17926 6.20569L8.44747 6.01091L8.71569 6.20569L8.61329 5.89048L8.88128 5.69571H8.54988L8.44747 5.3805Z" fill="white"/>
<path d="M8.54988 7.29243L8.44747 6.97722L8.34507 7.29243H8.01367L8.28166 7.4872L8.17926 7.80241L8.44747 7.60763L8.71569 7.80241L8.61329 7.4872L8.88128 7.29243H8.54988Z" fill="white"/>
<path d="M9.38497 1.3892L9.28257 1.7044H8.95117L9.21916 1.89918L9.11676 2.21438L9.38497 2.01961L9.65297 2.21438L9.55056 1.89918L9.81878 1.7044H9.48738L9.38497 1.3892Z" fill="white"/>
<path d="M9.38497 2.98592L9.28257 3.30112H8.95117L9.21916 3.4959L9.11676 3.8111L9.38497 3.61632L9.65297 3.8111L9.55056 3.4959L9.81878 3.30112H9.48738L9.38497 2.98592Z" fill="white"/>
<path d="M9.38497 4.58224L9.28257 4.89745H8.95117L9.21916 5.09222L9.11676 5.40743L9.38497 5.21265L9.65297 5.40743L9.55056 5.09222L9.81878 4.89745H9.48738L9.38497 4.58224Z" fill="white"/>
<path d="M9.48738 6.49397L9.38497 6.17876L9.28257 6.49397H8.95117L9.21916 6.68874L9.11676 7.00395L9.38497 6.80917L9.65297 7.00395L9.55056 6.68874L9.81878 6.49397H9.48738Z" fill="white"/>
<path d="M10.1569 1.10092L10.0545 1.41612L10.3225 1.22134L10.5907 1.41612L10.4883 1.10092L10.7563 0.906141H10.4249L10.3225 0.590939L10.2201 0.906141H9.88867L10.1569 1.10092Z" fill="white"/>
<path d="M10.3225 2.18746L10.2201 2.50266H9.88867L10.1569 2.69744L10.0545 3.01264L10.3225 2.81787L10.5907 3.01264L10.4883 2.69744L10.7563 2.50266H10.4249L10.3225 2.18746Z" fill="white"/>
<path d="M10.3225 3.78398L10.2201 4.09918H9.88867L10.1569 4.29396L10.0545 4.60916L10.3225 4.41439L10.5907 4.60916L10.4883 4.29396L10.7563 4.09918H10.4249L10.3225 3.78398Z" fill="white"/>
<path d="M10.3225 5.3805L10.2201 5.69571H9.88867L10.1569 5.89048L10.0545 6.20569L10.3225 6.01091L10.5907 6.20569L10.4883 5.89048L10.7563 5.69571H10.4249L10.3225 5.3805Z" fill="white"/>
<path d="M10.4249 7.29243L10.3225 6.97722L10.2201 7.29243H9.88867L10.1569 7.4872L10.0545 7.80241L10.3225 7.60763L10.5907 7.80241L10.4883 7.4872L10.7563 7.29243H10.4249Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_892:49619">
<rect y="0.249023" width="22.6176" height="14.6349" rx="3.32612" fill="white"/>
</clipPath>
</defs>
</svg>
`
]